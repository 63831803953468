<template>
  <!-- <NavBar></NavBar> -->
  <div class="client-banner">
    <header>
      <ul class="py-5 d-flex justify-space-around">
        <li><NuxtLink to="/">Home</NuxtLink></li>
        <li><NuxtLink to="/jobs">Jobs</NuxtLink></li>
        <li><NuxtLink to="/about">About</NuxtLink></li>
        <li><NuxtLink to="/faq">Faq</NuxtLink></li>
      </ul>
    </header>
    <v-row class="pa-8 text-white d-flex align-end">
      <v-col class="text-lg-start pa-lg-10 d-flex align-end" cols="12" lg="6">
        <div class="filter pa-5">
          <h2 class="text-h4 font-weight-bold">
            Lifeline Educational Solutions
          </h2>
          <h6 class="text-body-1">
            Get detailed information on the job you seek.
          </h6>
        </div>
      </v-col>
      <v-col cols="12" lg="6"></v-col>
    </v-row>
  </div>

  <div class="py-2">
    <h2 class="text-h5">Teaching Jobs</h2>
    <h5 class="text-medium-emphasis">Access Teaching Jobs Here</h5>
  </div>

  <div>
    <slot />
  </div>

  <ContactUs></ContactUs>
  <Footer></Footer>
</template>

<style scoped></style>
